// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-dataannotation-index-js": () => import("./../../../src/pages/dataannotation/index.js" /* webpackChunkName: "component---src-pages-dataannotation-index-js" */),
  "component---src-pages-datacollection-index-js": () => import("./../../../src/pages/datacollection/index.js" /* webpackChunkName: "component---src-pages-datacollection-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-requester-landing-requesterlanding-js": () => import("./../../../src/pages/requesterLanding/requesterlanding.js" /* webpackChunkName: "component---src-pages-requester-landing-requesterlanding-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-usecases-index-js": () => import("./../../../src/pages/usecases/index.js" /* webpackChunkName: "component---src-pages-usecases-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-workerlanding-index-js": () => import("./../../../src/pages/workerlanding/index.js" /* webpackChunkName: "component---src-pages-workerlanding-index-js" */)
}

